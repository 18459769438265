.tippy-box[data-theme~='custom'] {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border: 1px solid #ccc;
    font-family: "GothamRoundedLight", "Noto Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 400;
  }
  
  .tippy-arrow {
    color: rgb(4, 4, 4);
  }
  