/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 92%, 26%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --hovertransition: 0.3s;
  --spacing: 0.1rem;
  --radius: 3rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  --max-width: 90%;
  --fixed-width: 100%;
  --clr-orange-1: hsl(12, 83%, 98%);
  --clr-orange-2: hsl(14, 91%, 95%);
  --clr-orange-3: hsl(12, 89%, 89%);
  --clr-orange-4: hsl(13, 87%, 82%);
  --clr-orange-5: hsl(13, 88%, 68%);
  --clr-orange-6: hsl(13, 88%, 55%);
  --clr-orange-7: hsl(13, 74%, 49%);
  --clr-orange-8: hsl(13, 74%, 33%);
  --clr-orange-9: hsl(13, 73%, 25%);
  --clr-orange-10: hsl(13, 73%, 16%);
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "GothamRoundedLight";
  src: url("./fonts/Gotham Rounded Light.otf") format("opentype");
}

@font-face {
  font-family: "FSAlbertLight";
  src: url("./fonts/FSAlbert Light.otf") format("opentype");
}

@font-face {
  font-family: "FSAlbertRegular";
  src: url("./fonts/FSAlbert Regular.otf") format("opentype");
}

body {
  font-family: "FSAlbertLight", "Noto Sans", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  /* background: rgb(34, 32, 32); */
  background: #fff;
  color: #fff;
  line-height: 1.5;
  font-size: 0.875rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  margin-top: 1.25rem;
}

h1 {
  font-size: 3rem;
  font-family: "GothamRoundedLight", "Noto Sans", Arial, "Helvetica Neue",
    Helvetica, sans-serif;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1rem;
  font-family: "GothamRoundedLight", "Noto Sans", Arial, "Helvetica Neue",
    Helvetica, sans-serif;
}

h4 {
  font-size: 0.875rem;
}

.topInformation {
  background-color: #239fdd;
  padding: 3px;
  flex-direction: row;
}

/* p {
  font-family: "FSAlbertLight", "Noto Sans", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  color: black;
  margin-bottom: 0.75rem;
  text-align: left;
} */

div .main {
  padding-top: 8px;
  margin-bottom: -0.15rem;
  color: var(--clr-grey-5);
  margin-left: 5rem;
}

#wrapper {
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: auto;
  float: right;
}

div .rest {
  color: var(--clr-grey-5);
  margin-left: 5rem;
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: -2%;
  z-index: 1;
}

.slick-prev:before {
  content: "←";
  margin-left: 43rem;
}

.slick-next:before {
  content: "→";
  margin-left: -3rem;
}

.slides {
  height: 100vh;
  width: 100vh;
}

.slider {
  margin: left 50px;
  justify-content: center;
}

.arrows {
  padding-bottom: 8px;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1rem;
  }

  body {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1.25;
  }
}

/*  global classes */

/* section */
.section,
.container {
  width: 80vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}
label {
  font-size: 1rem;
  /* margin-right: 256px; */
}

.firstbox {
  height: 18px;
  width: 20px;
  padding-bottom: 0%;
  margin-right: 7px;
}
.SelectAll {
  text-align: right;
}

.firstCont {
  margin: 20px 10px 15px 5px;
  /* background-color: #000; */
  padding: 0.5%;
}

.container {
  text-align: left;
  margin-top: 1rem;
}
.users input {
  height: 20px;
  width: 20px;
}

.imageGrid h3 {
  text-align: left;
}

.btn {
  display: inline-block;
  background: var(--clr-primary-5);
  color: var(--clr-white);
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;
  text-transform: capitalize;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: var(--transition);
}

.btn-primary {
  display: inline-block;
  font-weight: 400; /* Bootstrap uses font-weight of 400 for buttons */
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem; /* Similar padding to Bootstrap's default button */
  font-size: 1rem; /* Consistent font size */
  line-height: 1.5; /* Consistent line height */
  border-radius: 0.25rem; /* Bootstrap's default button border radius */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; /* Basic button transition effects */
  cursor: pointer; /* Indicates interactivity */
  margin-bottom: 1rem;
  display: flex;
}

.pagination {
  position: fixed;
  bottom: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 81%;
  background-color: #239fdd;
}

.page {
  display: flex;
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
}

.pagination li.active button {
  background-color: #333;
  color: #fff;
}

.color-button {
  background-color: #ffffff;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  cursor: pointer;
}


.btn-download {
  display: inline-block;
  background: #239fdd;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;
  text-transform: capitalize;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  margin-top: 2rem;
  margin-left: 10rem;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: var(--transition);
}
.btn-download:hover {
  background: var(--clr-primary-1);
  color: var(--clr-primary-5);
  text-decoration: underline;
}

.btnclosewind {
  display: inline-block;
  background: var(--clr-primary-5);
  color: var(--clr-white);
  padding: 1rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;
  text-transform: capitalize;
  font-size: 1.5rem;
  letter-spacing: var(--spacing);
  margin-left: 2rem;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: var(--transition);
  margin: 0 auto;
  font-family: "FSAlbertRegular", sans-serif;
}

.btnclose:hover {
  background: var(--clr-primary-1);
  color: var(--clr-primary-5);
}

.btnclosewind:hover {
  background: var(--clr-primary-1);
  color: var(--clr-primary-5);
}

.btn:hover {
  background: var(--clr-primary-1);
  color: var(--clr-primary-5);
}

.item {
  background: var(--clr-white);
  display: flex;
  justify-content: space-between;
  max-width: var(--fixed-width);
  margin: 2rem auto;
  align-items: center;
  border-radius: var(--radius);
}

.item button,
.item a {
  background: transparent;
  border-color: transparent;
  color: var(--clr-primary-5);
  letter-spacing: var(--spacing);
  cursor: pointer;
}

.item {
  padding: 1rem 2rem;
}

.item h4 {
  margin-bottom: 0;
}

.item p {
  margin-bottom: 0;
}

.modal {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background: var(--clr-white);
  display: inline-block;
  padding: 0.25rem 1rem;
  border-radius: var(--radius);
  text-transform: capitalize;
}

.modal p {
  margin-bottom: 0;
  color: var(--clr-red-dark);
}

.form {
  background: var(--clr-white);
  max-width: var(--fixed-width);
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 1rem 2rem;
  border-radius: var(--radius);
}

.form input {
  background: var(--clr-grey-10);
  border-color: transparent;
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
}

.form-control {
  margin: 0.5rem 0;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
}

.form button {
  display: inline-block;
  background: var(--clr-black);
  color: var(--clr-white);
  border-color: transparent;
  margin-top: 1rem;
  letter-spacing: var(--spacing);
  padding: 0.15rem 0.25rem;
  text-transform: capitalize;
  border-radius: var(--radius);
  cursor: pointer;
}

.nav-links {
  max-width: var(--fixed-width);
  margin: 0 auto;
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.nav-links a {
  color: var(--clr-grey-5);
}

.users {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 2rem auto;
  background-color: #fff;
}

.users li {
  width: 100%;
  display: flex;
  background: #fff;
  align-items: center;
  /* padding: 1rem 2rem; */
  border-radius: 0px;
  text-align: left;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  border: solid 1px rgb(177, 166, 166);
}

.imageGrid input[type="text"] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: #222;
  border-radius: 10px;
  margin-left: 30px;
}

.image {
  text-align: center;
}

.img-responsive-height {
  display: block;
  width: auto;
  max-height: 100%;
}

/* .imageGrid button { */
/* float: right; */
/* padding: 10px 10px;
  margin-top: 8px;
  margin-right: 16px;
  margin-left: 25px;
  font-size: 17px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
} */

.closebtn {
  float: right;
}

.image img {
  height: 600px;
  width: 550px;
}

/* --lIGHTbOXcSSsTARTS-- */

.ReactModal__Body--open .ril__outer {
  background-color: #fff;
}

.ReactModal__Body--open .ril__toolbar {
  background-color: transparent;
}

.ReactModal__Body--open .ril-toolbar-right .ril__toolbarItem .ril-close {
  color: #000000;
  background-color: rgb(14, 13, 13);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 2.3em;
  margin-top: 20px;
}

.ReactModal__Body--open .ril-toolbar-right .ril__toolbarItem:last-child {
  display: inline-block;
}

@media only screen and (max-width: 1023px) {
  /* my temporary solution
    .ReactModal__Body--open .ril__image{
      transform: none !important;
    }
    */
  .ReactModal__Body--open .ril-toolbar-right .ril__toolbarItem {
    width: 40px;
    height: 40px;
    line-height: 1.8em;
  }
}

@keyframes closeWindow {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ReactModal__Body--open .ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ReactModal__Body--open .ril__outerClosing {
  opacity: 0;
}

.ReactModal__Body--open .ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #239fdd;
}

element.style {
  transform: none;
}

.ReactModal__Body--open .ril__outer {
  background-color: #62a2c2;
}

.ReactModal__Body--open .ril__image,
.ReactModal__Body--open .ril__imagePrev,
.ReactModal__Body--open .ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 480px;
  -ms-content-zooming: none;
  user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ReactModal__Body--open .ril-image-current {
  transform: none !important;
}

.ReactModal__Body--open .ril-toolbar-right .ril-zoom-in {
  display: none;
}

.ReactModal__Body--open .ril-zoom-out {
  display: none;
}

.ReactModal__Body--open .ril__imageDiscourager {
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
}

.ReactModal__Body--open .ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7;
}

.ReactModal__Body--open .ril__navButtons:hover {
  opacity: 1;
}

.ReactModal__Body--open .ril__navButtons:active {
  opacity: 0.7;
}

.ReactModal__Body--open .ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2)
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==")
    no-repeat center;
}

.ReactModal__Body--open .ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2)
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+")
    no-repeat center;
}

.ReactModal__Body--open .ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  background-size: cover;
}

.ReactModal__Body--open .ril__caption,
.ReactModal__Body--open .ril__toolbar {
  background-color: transparent;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  font-family: "FSAlbertRegular", "Noto Sans", Arial, "Helvetica Neue",
    Helvetica, sans-serif;
}

.ReactModal__Body--open .ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ReactModal__Body--open .ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ReactModal__Body--open .ril__toolbar {
  top: 0;
  height: 50px;
}

.ReactModal__Body--open .ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ReactModal__Body--open .ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactModal__Body--open .ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  flex: 0 0 auto;
}

.ReactModal__Body--open .ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactModal__Body--open .ril__toolbarItemChild {
  vertical-align: middle;
}

.ReactModal__Body--open .ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
}

.ReactModal__Body--open .ril__builtinButton:hover {
  opacity: 1;
}

.ReactModal__Body--open .ril__builtinButton:active {
  outline: none;
}

.ReactModal__Body--open .ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}

.ReactModal__Body--open .ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ReactModal__Body--open .ril__closeButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=")
    no-repeat center;
}

.ReactModal__Body--open .ril__zoomInButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+")
    no-repeat center;
}

.ReactModal__Body--open .ril__zoomOutButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=")
    no-repeat center;
}

.ReactModal__Body--open .ril__outerAnimating {
  animation-name: closeWindow;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }
}

.ReactModal__Body--open .ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ReactModal__Body--open .ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.ReactModal__Body--open .ril__loadingCirclePoint::before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #000;
  border-radius: 30%;
  animation: pointFade 3000ms infinite ease-in-out both;
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(1)::before,
.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms;
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(2)::before,
.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms;
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(3)::before,
.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms;
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(4)::before,
.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms;
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(5)::before,
.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms;
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(6)::before,
.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms;
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}

.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(7)::before,
.ReactModal__Body--open .ril__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms;
}

.ReactModal__Body--open .ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ReactModal__Body--open .ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ReactModal__Body--open .ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ReactModal__Body--open .ril__imagePrev .ril__errorContainer,
.ReactModal__Body--open .ril__imageNext .ril__errorContainer {
  display: none;
}

.ReactModal__Body--open .ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* --lIGHTbOXcSSeNDS-- */

.users img {
  /* width: 270px; */
  width: 100%;
  /* height: 312px; */
  height: 100%;
  margin-right: 0.5rem;
  /* margin-left: 0.5rem; */
  padding: 5px;
  cursor: pointer;
  transition: var(--hovertransition);
}

.users p {
  font-size: 13px;
  color: black;
}

.button {
  display: inline-block;
  width: 270px;
  height: 312px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 5px;
  cursor: pointer;
  transition: var(--hovertransition);
  background-image: url("./icons/DocLogo.png");
  background-size: 270px 312px;
}

.buttonVideo {
  display: inline-block;
  width: 270px;
  height: 312px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 5px;
  cursor: pointer;
  transition: var(--hovertransition);
  background-image: url("./icons/video.jpg");
  background-size: 270px 312px;
  position: relative;
  text-align: center;
  font-weight: bold; 
  color: #239fdd !important; 
}

.buttonDoc {
  display: inline-block;
  width: 270px;
  height: 312px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 5px;
  cursor: pointer;
  transition: var(--hovertransition);
  background-image: url("./icons/doc.jpg");
  background-size: 270px 312px;
}
.buttonXls {
  display: inline-block;
  width: 270px;
  height: 312px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 5px;
  cursor: pointer;
  transition: var(--hovertransition);
  background-image: url("./icons/excel.jpg");
  background-size: 270px 312px;
}
.buttonPdf {
  display: inline-block;
  width: 270px;
  height: 312px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 5px;
  cursor: pointer;
  transition: var(--hovertransition);
  background-image: url("./icons/pdf.jpg");
  background-size: 270px 312px;
}
.buttonPpt {
  display: inline-block;
  width: 270px;
  height: 312px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 5px;
  cursor: pointer;
  transition: var(--hovertransition);
  background-image: url("./icons/ppt.jpg");
  background-size: 270px 312px;
}
.imgInfo {
  width: 100%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 5px;
  overflow: auto;
  font-family: "FSAlbertLight", "Noto Sans", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  color: #000 !important;
  margin-bottom: 0.75rem;
  text-align: left;
}

.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(180deg);
}

.users li:hover {
  box-shadow: 0 0 12px #239fdd;
  transform: scale(1.01);
}

/* .woli {
  margin-top: 1rem;
  list-style-type: none;
  margin-left: 10px;
  margin-right: 10px;
} */

.errorsec {
  align-items: center;
}

.errMsg {
  text-align: center;
  border-style: solid;
  border-radius: 10px;
  padding-bottom: 50px;
  border-color: rgb(90, 88, 88);
}

.mainerror {
  color: rgb(24, 22, 44);
  text-align: center;
  padding-top: 150px;
  text-transform: none;
  font-size: 3rem;
  padding-bottom: 50px;
  padding-bottom: 50px;
}

.error {
  color: rgb(24, 22, 44);
  text-align: center;
  padding-top: 10px;
  text-transform: none;
  font-size: 1rem;
  padding-bottom: 50px;
}

.woli h5 {
  margin-left: 0px;
  background: #000;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  height: 25px;
  padding: 3px;
  font-family: "FSAlbertLight", sans-serif;
}

.woli h6 {
  background: #000;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  height: 25px;
  padding: 3px;
  font-family: "FSAlbertLight", sans-serif;
  margin-top: 10px;
}

.users h4 {
  margin-bottom: 0.15rem;
}

.users a {
  color: grey;
  text-transform: capitalize;
  padding: 10px;
  font-family: "FSAlbertLight", sans-serif;
}

.products {
  margin: 4rem 0;
  display: grid;
  gap: 2rem;
}

@media screen and (min-width: 576px) {
  .products {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.product {
  background: var(--clr-white);
  border-radius: var(--radius);
}

.product img {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);

  width: 100%;
  height: 15rem;
  object-fit: cover;
}

.product h4 {
  margin-top: 1rem;
}

.product button {
  margin-bottom: 1rem;
  background: var(--clr-primary-5);
  border-radius: var(--radius);
  border-color: transparent;
  color: var(--clr-white);
  padding: 0.25rem 0.5rem;
  text-transform: capitalize;
  cursor: pointer;
}

/* Release 4.1 starts*/

.firstCont {
  font-size: smaller !important;
}
.submitShowOnWeb {
  text-align: right !important;
}

.submit-flag {
  font-size: x-large !important;
  background-color: #239fdd !important;
  color: white !important;
  border: none !important;
  padding: 10px;
  margin-top: 8px;
  margin-right: 16px;
  margin-left: 25px;
  border-radius: 10px;
}

.submit-flag:hover {
  background-color: white !important;
  color: #239fdd !important;
}

.submit-flag.disabled {
  background-color: grey !important;
  cursor: not-allowed;
}

.btn-download {
  padding: 10px 10px;
  margin-top: 8px;
  margin-right: 16px;
  margin-left: 25px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.5rem !important;
  /* background-color: #239fdd !important; */
  background-color: whitesmoke !important;
  /* color: white !important; */
  color: #239fdd !important;
  border: none !important;
  text-decoration: none !important;
}

.btn-download:hover {
  background-color: white !important;
  color: #239fdd !important;
}

.btn-download:disabled {
  /* background-color: light-dark(rgba(55, 51, 51, 0.3), rgba(255, 255, 255, 0.3)) !important; */
  background-color: white !important;
  color: #239fdd !important;
  opacity: 0.6;
}

.showOnwebLabel {
  color: black;
}

.showOnwebLabel input[type="checkbox"] {
  font-weight: bold;
}

/* Create a blurred overlay */
.dialog-overlay {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99; /* Set the z-index behind the dialog box */
  backdrop-filter: blur(5px);
}

/* Style the dialog box */
#dialog-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  /* width: 400px; */
  width: 570px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

/* Apply the blurred overlay behind the dialog box */
.dialog-open .dialog-overlay {
  display: block;
}

/* Show the dialog box */
.dialog-open #dialog-box {
  display: block;
}

#dialog-box-title {
  font-size: 20px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
  background-color: #239fdd;
}

#dialog-box-message {
  padding: 25px;
  color: black;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

#dialog-box-close-button {
  padding: 10px 20px 10px 15px;
  border: none;
  cursor: pointer;
  position: relative;
  /* left: 300px; */
  left: 460px;
  bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #239fdd;
  color: white;
}

#dialog-box-close-button:hover {
  background-color: #d9d9d9;
  color: black;
}
#dialog-box-yes-button {
  padding: 10px 20px 10px 15px;
  border: none;
  cursor: pointer;
  position: relative;
  /* left: 240px; */
  left: 370px;
  bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #239fdd;
  color: white;
}

#dialog-box-yes-button:hover {
  background-color: #d9d9d9;
  color: black;
}
#dialog-box-no-button {
  padding: 10px 20px 10px 15px;
  border: none;
  cursor: pointer;
  position: relative;
  /* left: 265px; */
  left: 420px;
  bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #239fdd;
  color: white;
}

#dialog-box-no-button:hover {
  background-color: #d9d9d9;
  color: black;
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}
/* Add a new overlay element */
#loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

#loader::after {
  content: "";
  width: 75px;
  height: 75px;
  border: 15px solid #dddddd;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: loading 0.75s ease infinite;
}
@keyframes loading {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

/* Release 4.1 ends*/

.siteName {
  margin-top: 1rem;
  list-style-type: none;
}

.siteName h5 {
  margin-left: 0px;
  background: #000;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  height: 25px;
  padding: 3px;
  font-family: "FSAlbertLight", sans-serif;
}

.siteName h6 {
  background: #000;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  height: 25px;
  padding: 3px;
  font-family: "FSAlbertLight", sans-serif;
}

.siteNameBar {
  display: flex; /* Make the children inline */
  align-items: center; /* Vertically center the content */
  background-color: black; /* Set the background color to black */
  color: white; /* Set the text color to white */
  padding: 5px; /* Add padding for spacing */
}

.siteNameBar p {
  font-family: "FSAlbertLight", "Noto Sans", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  color: white;
}

.siteNameButton {
  margin-right: 10px; /* Add margin to separate the button from text */
  background: none; /* Remove button background */
  border: none; /* Remove button border */
  color: white; /* Set button text color to white */
  font-size: 25px; /* Adjust the icon size */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.patchNameItem {
  display: flex; /* Make the children inline */
  align-items: center; /* Vertically center the content */
  background-color: black; /* Set the background color to black */
  color: white; /* Set the text color to white */
  padding: 5px; /* Add padding for spacing */
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.patchNameItem p {
  font-family: "FSAlbertLight", "Noto Sans", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  color: white;
}

.patchNameButton {
  margin-right: 10px; /* Add margin to separate the button from text */
  background: none; /* Remove button background */
  border: none; /* Remove button border */
  color: white; /* Set button text color to white */
  font-size: 25px; /* Adjust the icon size */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.woli {
  margin-top: 10px; /* Add margin to separate woli items */
  list-style-type: none;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff; /* Add a background color if needed */
}
.woliNonReinstement {
  margin-top: 10px; /* Add margin to separate woli items */
  list-style-type: none;
  background-color: #fff; /* Add a background color if needed */
}

.woliNonReinstement h5 {
  margin-left: 0px;
  background: #000;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  height: 25px;
  padding: 3px;
  font-family: "FSAlbertLight", sans-serif;
}

.woliNonReinstement h6 {
  background: #000;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  height: 25px;
  padding: 3px;
  font-family: "FSAlbertLight", sans-serif;
  margin-top: 10px;
}

.woliWithoutPatch,
.woliWithoutWorkType {
  margin-top: 10px; /* Add margin to separate woli items */
  list-style-type: none;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #fff; /* Add a background color if needed */
}

.woliWithoutPatch h5,
.woliWithoutWorkType h5 {
  margin-left: 0px;
  background: #000;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  height: 25px;
  padding: 3px;
  font-family: "FSAlbertLight", sans-serif;
}

.woliWithoutPatch h6,
.woliWithoutWorkType h6 {
  background: #000;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  height: 25px;
  padding: 3px;
  font-family: "FSAlbertLight", sans-serif;
  margin-top: 10px;
}

.workTypeItem {
  display: flex; /* Make the children inline */
  align-items: center; /* Vertically center the content */
  background-color: black; /* Set the background color to black */
  color: white; /* Set the text color to white */
  padding: 5px; /* Add padding for spacing */
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.workTypeItem p {
  font-family: "FSAlbertLight", "Noto Sans", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  color: white;
}

.workTypeButton {
  margin-right: 10px; /* Add margin to separate the button from text */
  background: none; /* Remove button background */
  border: none; /* Remove button border */
  color: white; /* Set button text color to white */
  font-size: 25px; /* Adjust the icon size */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.docInfo {
  width: 100%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 5px;
  overflow: auto;
  font-family: "FSAlbertLight", "Noto Sans", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  color: #000 !important;
  margin-bottom: 0.75rem;
  text-align: left;
}

.toggleImageCaptionButton {
  background: white;
  color: black;
  padding: 0.25rem 0.75rem;
  border-color: transparent;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  margin-right: 3.5rem;
  cursor: pointer;
}
